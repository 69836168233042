<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <Form class = "float-left" key="AddContract" :name="'AddContract'" :loadFormData="true" :isEditing="true"/>
  </div>
</template>

<script>
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import Form from '../../../shared/components/Form.vue'
import EventBus from '@/app/shared/event-bus.js'
export default {
  name: 'App',
  components: {
    Breadcrumbs,
    Form
  },
  data: () => ({
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: 'add_contract',
        link: '',
        class: 'active'
      }
    ]
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  methods: {
    formSavedSuccessfully ({ name, response }) {
      this.$router.push({
        name: 'Targeted Details',
        params: { editSection: true, id: response.Data[0].reportingUnitId }
      })
    }
  }
}
</script>

<style scoped>
* {
  margin: revert;
  box-sizing: unset;
}
</style>
